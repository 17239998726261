import React from "react"
import { Link } from 'gatsby'

import Layout from "../components/layout";
import SEO from "../components/seo";


const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="not-found-page">
    <h1>Ooops!</h1>
    <p>We can't find the page you were looking for. This is maybe because it has been removed, we've changed its name or it is temporarily unavailable.</p>
    <Link to="/contact/" className="primary-button btn btn-primary">Contact Us</Link>
	  <Link to="/" className="primary-button btn alt btn">Homepage</Link>
    </div>
  </Layout>
)

export default NotFoundPage
